<template>
  <div>
    <div
      class="header"
      :style="{
        display: pageWidth < 700 ? 'none' : 'flex',
      }"
    >
      <el-container>
        <el-header>
          <div class="header_l">
            <img src="@/assets/logo.png" alt="" />
          </div>
          <div class="header_r">
            <el-menu :default-active="onRoutes" mode="horizontal">
              <div v-for="(item, index) in navList" :key="index">
                <el-menu-item @click="goCon(item.path)" :index="item.path">
                  {{ item.title }}</el-menu-item
                >
              </div>
              <el-menu-item index="5">
                <el-dropdown>
                  <span
                    class="el-dropdown-link"
                    @click="goConFooter('/joinCommunity')"
                  >
                    加入社区<i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item>
                      <a
                        href="https://t.me/CNCarpoolLifeEconomy"
                        target="_blank"
                      >
                        Telegram
                      </a>
                    </el-dropdown-item>
                    <el-dropdown-item>
                      <a href="https://twitter.com/CPLE120Y" target="_blank">
                        Twitter
                      </a>
                    </el-dropdown-item>
                    <el-dropdown-item>
                      <a href="https://discord.gg/wPKjU3YW6c" target="_blank">
                        Discord
                      </a>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </el-menu-item>

              <el-menu-item index="6">
                <el-dropdown @command="handleCommand">
                  <span class="el-dropdown-link">
                    {{ language
                    }}<i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="简体中文"
                      >简体中文</el-dropdown-item
                    >
                    <el-dropdown-item command="繁体中文"
                      >繁体中文</el-dropdown-item
                    >
                    <el-dropdown-item command="English"
                      >English</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </el-dropdown>
              </el-menu-item>
            </el-menu>
          </div>
        </el-header>
        <el-main style="padding: 0; margin-top: 110px">
          <router-view name="main"
        /></el-main>

        <el-footer>
          <div style="display: flex; justify-content: space-between">
            <div class="footer_l">
              <img src="@/assets/logo3.png" alt="" />
              <h3>共享收益的拼车生活平台</h3>
              <div class="footer_l_navlink">
                <p @click="goConFooter('/')">首页</p>
                <p>/</p>
                <p @click="goConFooter('/information')">资讯动态</p>
                <p>/</p>
                <p @click="goConFooter('/aboutUs')">关于我们</p>
                <p>/</p>
                <p @click="goConFooter('/contactUs')">联系我们</p>
                <p>/</p>
                <p @click="goConFooter('/joinCommunity')">加入社区</p>
              </div>
            </div>
            <div class="footet_r">
              <img src="./assets/erweima.png" alt="" />
              <img src="./assets/erweima2.png" alt="" />
            </div>
          </div>

          <div class="footer_hr"></div>
          <div class="footer_record">
            <a
              href="https://beian.miit.gov.cn/#/Integrated/index"
              target="_blank"
              >京ICP备17038918号-1</a
            >
          </div>
        </el-footer>
      </el-container>
    </div>

    <div
      class="header_media"
      :style="{
        display: pageWidth < 700 ? 'flex' : 'none',
      }"
    >
      <el-container>
        <el-header>
          <div class="header_l">
            <img src="@/assets/logo.png" alt="" />
          </div>
          <div class="header_r">
            <i class="iconfont icon-dawu" @click="showPopup"></i>
            <van-popup
              v-model="showTop"
              position="top"
              :style="{ height: '100%' }"
              closeable
            >
              <div class="media_header_list">
                <el-menu
                  :default-active="onRoutes"
                  class="el-menu-vertical-demo"
                >
                  <div v-for="(item, index) in navList" :key="index">
                    <el-menu-item @click="goCon(item.path)" :index="item.path">
                      {{ item.title }}
                      <img src="@/assets/jiantou.png" alt=""
                    /></el-menu-item>
                  </div>
                  <el-menu-item @click="goCon('/joinCommunity')">
                    加入社区
                    <img src="@/assets/jiantou.png" alt=""
                  /></el-menu-item>

                  <el-menu-item index="6">
                    <el-dropdown @command="handleCommand">
                      <span class="el-dropdown-link">
                        {{ language
                        }}<i class="el-icon-arrow-down el-icon--right"></i>
                      </span>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item command="简体中文"
                          >简体中文</el-dropdown-item
                        >
                        <el-dropdown-item command="繁体中文"
                          >繁体中文</el-dropdown-item
                        >
                        <el-dropdown-item command="English"
                          >English</el-dropdown-item
                        >
                      </el-dropdown-menu>
                    </el-dropdown>
                  </el-menu-item>
                </el-menu>
              </div>
            </van-popup>
          </div>
        </el-header>

        <el-main style="padding: 0; margin-top: 110px; width: 95%">
          <router-view name="main"
        /></el-main>

        <el-footer>
          <div class="footer_media">
            <img src="@/assets/logo3.png" class="footer_media_logo" alt="" />
            <h3>共享收益的拼车生活平台</h3>
            <img src="./assets/erweima.png" alt="" />
            <img src="./assets/erweima2.png" alt="" />

            <div class="footer_hr"></div>
            <div class="footer_record">京ICP备17038918号-1</div>
          </div>
        </el-footer>
      </el-container>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { Popup } from "vant";
import "vant/lib/index.css";
Vue.use(Popup);

import "./index.scss";
export default {
  data() {
    return {
      language: "" || "English",

      navList: [
        {
          title: "首页",
          path: "/",
        },
        {
          title: "资讯动态",
          path: "/information",
        },
        {
          title: "关于我们",
          path: "/aboutUs",
        },
        {
          title: "联系我们",
          path: "/contactUs",
        },
      ],

      pageWidth: 0,
      showTop: false,
    };
  },

  mounted() {
    console.log(window.innerWidth);
    this.pageWidth = window.innerWidth;
  },

  computed: {
    // 动态返回对应的路由path
    onRoutes() {
      return this.$route.path;
    },
  },

  methods: {
    goCon(conE) {
      if (this.$route.path === conE) {
        return;
      } else {
        this.showTop = false;
        this.$router.push(conE);
      }
    },

    goConFooter(path) {
      this.$router.push(path);
    },

    handleCommand(command) {
      this.language = command;
    },

    showPopup() {
      this.showTop = true;
    },
  },
};
</script>
