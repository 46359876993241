<template>
  <div>
    <router-view v-if="isRefresh" />
  </div>
</template>

<script>
export default {
  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      isRefresh: true,
    };
  },
  methods: {
    reload() {
      this.isRefresh = false;
      this.$nextTick(() => {
        this.isRefresh = true;
      });
    },
  },

  mounted() {
    var script = document.createElement("meta");
    script.name = "viewport";
    script.content = "width=device-width, user-scalable=no";
    document.getElementsByTagName("head")[0].appendChild(script);

    // 禁止双指放大
    document.documentElement.addEventListener(
      "touchstart",
      function (event) {
        if (event.touches.length > 1) {
          event.preventDefault();
        }
      },
      {
        passive: false,
      }
    );

    // 禁用双击放大
    var lastTouchEnd = 0;
    document.documentElement.addEventListener(
      "touchend",
      function (event) {
        var now = Date.now();
        if (now - lastTouchEnd <= 300) {
          event.preventDefault();
        }
        lastTouchEnd = now;
      },
      {
        passive: false,
      }
    );
  },
};
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
  font-family: "PingFang SC";
  font-style: normal;
  text-decoration: none;
}

.website_header {
  margin-bottom: 35px;

  img {
    width: 146px;
    height: 34px;
    margin-bottom: 35px;
  }

  h1 {
    font-weight: 600;
    font-size: 64px;
  }
}
</style>
