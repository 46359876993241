import Vue from "vue";
import VueRouter from "vue-router";
import Index from "../Index.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "",
    component: Index,
    children: [
      {
        path: "/",
        name: "index",
        components: { main: () => import("../views/Home/index.vue") },
      },
      {
        path: "/information",
        name: "information",
        components: { main: () => import("../views/Information/index.vue") },
      },
      {
        path: "/aboutUs",
        name: "aboutUs",
        components: { main: () => import("../views/AboutUs/index.vue") },
      },
      {
        path: "/contactUs",
        name: "contactUs",
        components: { main: () => import("../views/ContactUs/index.vue") },
      },
      {
        path: "/joinCommunity",
        name: "joinCommunity",
        components: { main: () => import("../views/joinCommunity/index.vue") },
      },

      {
        path: "/Information/page",
        name: "page",
        components: {
          main: () => import("../views/Information/DetailPage.vue"),
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
